import { Controller } from '@stimulus/core'

import $ from 'jquery'

export default class extends Controller {
  connect(): void {
    this.change(null);
  }

  change(event: Event): void {
    var prayer = $("#entry_detail_prayer").val();
    var fee = $("#entry_detail_fee option:selected").val();
    
    $("#families").hide();
    $("#company").hide();
    $("#wish").hide();

    switch (prayer) {
      case "kanai_anzen":
        $("#families").show();
        break;
      case "shoubai_hanjo":
        $("#company").show();
        break;
      case "kids_other":
      case "other":
        $("#wish").show();
        break;
    }

    var fees;
    if (prayer == "yakubarai_3years") {
      fees = [ 25600 ];
    } else if (prayer.substring(0, 5) == "kids_") {
      fees = [ 3000, 6000, 10000, 20000, 30000 ];
    } else {
      fees = [ 6000, 10000, 20000, 30000 ];
    }

    $('#entry_detail_fee option').remove();
    $('#entry_detail_fee').append($('<option>').html("").val(""));

    $.each(fees, function(i, f) {
      $('#entry_detail_fee').append($('<option>').html(f.toLocaleString() + " 円").val(f));
    });

    $("#entry_detail_fee").val(fee);
  }
}
